// assets
import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconUsers,
  IconUserPlus,
  IconUserCheck,
  IconTrendingUp,
  IconBookUpload,
  IconInfoCircle,
  IconBusStop,
  IconStar,
  IconBell,
  IconMessage,
} from "@tabler/icons";

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconUsers,
  IconUserPlus,
  IconUserCheck,
  IconTrendingUp,
  IconBookUpload,
  IconInfoCircle,
  IconBusStop,
  IconStar,
  IconBell,
  IconMessage,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: "utilities",
  title: "",
  type: "group",
  children: [
    {
      id: "users",
      title: "Users",
      type: "item",
      url: "/users",
      icon: icons.IconUsers,
      breadcrumbs: false,
    },
    {
      id: "hosts",
      title: "Hosts",
      type: "item",
      url: "/hosts",
      icon: icons.IconUserPlus,
      breadcrumbs: false,
    },
    {
      id: "customers-list",
      title: "Customers",
      type: "item",
      url: "/customers",
      icon: icons.IconUserCheck,
      breadcrumbs: false,
    },
    {
      id: "transactions",
      title: "Transactions",
      type: "item",
      url: "/transactions",
      icon: icons.IconTrendingUp,
      breadcrumbs: false,
    },
    {
      id: "posts",
      title: "Posts",
      type: "item",
      url: "/posts",
      icon: icons.IconBookUpload,
      breadcrumbs: false,
    },
    {
      id: "posts-list",
      title: "Inquiries",
      type: "item",
      url: "/inquiries",
      icon: icons.IconInfoCircle,
      breadcrumbs: false,
    },
    {
      id: "destinations-list",
      title: "Destinations",
      type: "item",
      url: "/destinations",
      icon: icons.IconBusStop,
      breadcrumbs: false,
    },
    {
      id: "reviews",
      title: "Reviews",
      type: "item",
      url: "/reviews",
      icon: icons.IconStar,
      breadcrumbs: false,
    },
    {
      id: "notification",
      title: "Notifications",
      type: "item",
      url: "/notifications",
      icon: icons.IconBell,
      breadcrumbs: false,
    },
    {
      id: "messaging",
      title: "Messaging",
      type: "item",
      url: "/messaging",
      icon: icons.IconMessage,
      breadcrumbs: false,
    },
  ],
};

export default utilities;
