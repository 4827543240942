import { EVENT_TYPES, BOOK_EVENT_TYPES } from "store/types";
import {
  refactorEventObj,
  generateCSV_event_bookings,
  refactorBookingObj,
} from "utils/misc";

const INITIAL_STATE = {
  total_events: 0,
  events: [],
  event: {},
  bookings: [],
  booking: {},
  events_csv: [],
};

const eventReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EVENT_TYPES.FETCH_EVENT_SUCCESS:
      return {
        ...state,
        event: action.payload,
      };

    case EVENT_TYPES.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: refactorEventObj(action.payload.data),
        total_events: action.payload.results,
      };
    case BOOK_EVENT_TYPES.FETCH_BOOK_EVENTS_SUCCESS:
      return {
        ...state,
        bookings: refactorBookingObj(action.payload),
        events_csv: generateCSV_event_bookings(action.payload, "Event"),
      };
    case BOOK_EVENT_TYPES.FETCH_BOOK_EVENT_SUCCESS:
      return {
        ...state,
        booking: action.payload,
      };
    default:
      return state;
  }
};

export default eventReducer;
