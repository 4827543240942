import { GET_REVIEWS, REVIEWS } from "store/types";
import { refactorReviewObj } from "utils/misc";

const initState = {
  reviews: [],
  review: null,
  errorMessage: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case REVIEWS:
      return { ...state, review: action.payload };
    case GET_REVIEWS:
      return { ...state, reviews: refactorReviewObj(action.payload) };
    default:
      return state;
  }
};
