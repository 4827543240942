import { HOST_TYPES } from "store/types";
import { generateCSV_hostData, refactorHostsObj } from "utils/misc";

const INITIAL_STATE = {
  host: null,
  csv_data: [],
  host_details: null,
};

const hostReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HOST_TYPES.SET_HOST:
      return {
        ...state,
        host: refactorHostsObj(action.hosts),
        csv_data: generateCSV_hostData(action.hosts),
      };
    case HOST_TYPES.FETCH_HOST_DETAILS_SUCCESS:
      return {
        ...state,
        host_details: action.payload,
      };
    default:
      return state;
  }
};

export default hostReducers;
