import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import BulkMessage from "views/pages/messaging/bulkMessage";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// Experience Pages
const Experiences = Loadable(lazy(() => import("views/pages/experiences")));
const Experience = Loadable(
  lazy(() => import("views/pages/experiences/experience"))
);
const ExperienceBookings = Loadable(
  lazy(() => import("views/pages/experiences/Bookings"))
);
const Booking = Loadable(lazy(() => import("views/pages/experiences/booking")));

// Home Pages
const Homes = Loadable(lazy(() => import("views/pages/homes")));
const Home = Loadable(lazy(() => import("views/pages/homes/home")));
const Reservations = Loadable(
  lazy(() => import("views/pages/homes/reservations"))
);
const Reservation = Loadable(
  lazy(() => import("views/pages/homes/reservation"))
);
const HomeBookings = Loadable(lazy(() => import("views/pages/homes/bookings")));
const HomeBooking = Loadable(lazy(() => import("views/pages/homes/booking")));

// Store Pages
const Stores = Loadable(lazy(() => import("views/pages/shops")));
const Store = Loadable(lazy(() => import("views/pages/shops/shop")));
const Products = Loadable(lazy(() => import("views/pages/shops/products")));
const Product = Loadable(lazy(() => import("views/pages/shops/product")));
const Categories = Loadable(lazy(() => import("views/pages/shops/categories")));
const AddCategory = Loadable(
  lazy(() => import("views/pages/shops/addCategory"))
);

// Event Pages
const Events = Loadable(lazy(() => import("views/pages/events")));
const Event = Loadable(lazy(() => import("views/pages/events/event")));
const EventBookings = Loadable(
  lazy(() => import("views/pages/events/bookings"))
);
const EventBooking = Loadable(lazy(() => import("views/pages/events/booking")));

// User Pages
const Users = Loadable(lazy(() => import("views/pages/users")));
const UserWishLists = Loadable(
  lazy(() => import("views/pages/users/userWishLists"))
);
// Host Pages
const Hosts = Loadable(lazy(() => import("views/pages/hosts")));
const Host = Loadable(lazy(() => import("views/pages/hosts/host")));
// Customers Page
const Customers = Loadable(lazy(() => import("views/pages/customers")));
// Transactions Pages
const Transactions = Loadable(lazy(() => import("views/pages/transactions")));
// Posts Pages
const Posts = Loadable(lazy(() => import("views/pages/posts")));
// Destination Pages
const Destinations = Loadable(lazy(() => import("views/pages/destinations")));
const AddDestination = Loadable(
  lazy(() => import("views/pages/destinations/addDestination"))
);
// Inquiry Pages
const Inquiries = Loadable(lazy(() => import("views/pages/inquires")));
const Inquiry = Loadable(lazy(() => import("views/pages/inquires/inquiry")));
// Review Pages
const Reviews = Loadable(lazy(() => import("views/pages/reviews")));
const Review = Loadable(lazy(() => import("views/pages/reviews/review")));
// Notification Pages
const Notifications = Loadable(lazy(() => import("views/pages/notifications")));
const AddNotification = Loadable(
  lazy(() => import("views/pages/notifications/addNotification"))
);
const PersonalNotification = Loadable(
  lazy(() => import("views/pages/notifications/personalNotification"))
);
// Messaging
const Messages = Loadable(lazy(() => import("views/pages/messaging")));
const PersonalMessage = Loadable(
  lazy(() => import("views/pages/messaging/personalMessage"))
);
const BulkSMS = Loadable(
  lazy(() => import("views/pages/messaging/bulkMessage"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },

    //==============// EXPERIENCE ROUTES //===================//
    {
      path: "experiences",
      element: <Experiences />,
    },
    {
      path: "experience-bookings",
      element: <ExperienceBookings />,
    },
    {
      path: "experience-booking-details",
      element: <Booking />,
    },
    {
      path: "view-experience",
      element: <Experience />,
    },
    //==============// HOMES ROUTES //=====================//
    {
      path: "homes",
      element: <Homes />,
    },
    {
      path: "view-home",
      element: <Home />,
    },
    {
      path: "reservations",
      element: <Reservations />,
    },
    {
      path: "view-home-reservation",
      element: <Reservation />,
    },
    {
      path: "home-bookings",
      element: <HomeBookings />,
    },
    {
      path: "view-home-booking",
      element: <HomeBooking />,
    },
    //===========================// SHOP ROUTES //====================//
    {
      path: "stores",
      element: <Stores />,
    },
    {
      path: "view-store",
      element: <Store />,
    },
    {
      path: "products",
      element: <Products />,
    },
    {
      path: "view-product",
      element: <Product />,
    },
    {
      path: "categories",
      element: <Categories />,
    },
    {
      path: "add-category",
      element: <AddCategory />,
    },
    //==============// EVENT ROUTES //=====================//
    {
      path: "events",
      element: <Events />,
    },
    {
      path: "view-event",
      element: <Event />,
    },
    {
      path: "event-bookings",
      element: <EventBookings />,
    },
    {
      path: "event-booking",
      element: <EventBooking />,
    },
    //========================// USER ROUTES //======================//
    {
      path: "users",
      element: <Users />,
    },
    {
      path: "user-wishlists",
      element: <UserWishLists />,
    },
    // ====================// HOST ROUTES //=====================//
    {
      path: "hosts",
      element: <Hosts />,
    },
    {
      path: "view-host",
      element: <Host />,
    },
    // ======================// UTILITY ROUTES //====================//
    {
      path: "customers",
      element: <Customers />,
    },
    {
      path: "transactions",
      element: <Transactions />,
    },
    {
      path: "posts",
      element: <Posts />,
    },
    {
      path: "destinations",
      element: <Destinations />,
    },
    {
      path: "add-destination",
      element: <AddDestination />,
    },
    {
      path: "inquiries",
      element: <Inquiries />,
    },
    {
      path: "view-inquiry",
      element: <Inquiry />,
    },
    {
      path: "reviews",
      element: <Reviews />,
    },
    {
      path: "review",
      element: <Review />,
    },
    {
      path: "notifications",
      element: <Notifications />,
    },
    {
      path: "create-notification",
      element: <AddNotification />,
    },
    {
      path: "personal-notification",
      element: <PersonalNotification />,
    },
    {
      path: "messaging",
      element: <Messages />,
    },
    {
      path: "personal-sms",
      element: <PersonalMessage />,
    },
    {
      path: "bulk-sms",
      element: <BulkMessage />,
    },
  ],
};

export default MainRoutes;
