import dashboard from "./dashboard";
import pages from "./pages";
import utilities from "./utilities";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, pages, utilities],
};

export default menuItems;
