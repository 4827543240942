export const DASHBOARD_TYPES = {
  DATA_COUNTS: "DATA_COUNTS",
  DATA_BAR: "DATA_BAR",
};

export const AUTH_TYPES = {
  AUTH_SIGN_IN: "AUTH_SIGN_IN",
  AUTH_SIGN_OUT: "AUTH_SIGN_OUT",
  AUTH_ERROR: "AUTH_ERROR",
};

export const EXPERIENCE_TYPES = {
  SET_EXPERIENCE: "SET_EXPERIENCE",
  ADD_EXPERIENCE: "ADD_EXPERIENCE",
  GET_EXPERIENCE: "GET_EXPERIENCE",
  EDIT_EXPERIENCE: "EDIT_EXPERIENCE",
  EXPERIENCE_ERROR: "EXPERIENCE_ERROR",
};

export const HOME_TYPE = {
  SET_HOME: "SET_HOME",
  ADD_HOME: "ADD_HOME",
  GET_HOME: "GET_HOME",
  EDIT_HOME: "EDIT_HOME",
  HOME_ERROR: "HOME_ERROR",
};

export const CUSTOMER_TYPES = {
  FETCH_CUSTOMERS_SUCCESS: "FETCH_CUSTOMERS_SUCCESS",
  ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
};

export const BOOKING_TYPES = {
  SET_BOOKING: "SET_BOOKING",
  BOOKING: "BOOKING",
  BOOKING_ERROR: "BOOKING_ERROR",
  FETCH_HOME_BOOKINGS: "FETCH_HOME_BOOKINGS",
  FETCH_HOME_BOOKING_DETAILS: "FETCH_HOME_BOOKING_DETAILS",
};

export const DESTINATION_TYPES = {
  SET_DESTINATIONS: "SET_DESTINATIONS",
  ADD_DESTINATIONS: "ADD_DESTINATIONS",
  DESTINATIONS_ERROR: "DESTINATIONS_ERROR",
};

export const SLIDE_TYPES = {
  SET_SLIDE: "SET_SLIDE",
  ADD_SLIDE: "ADD_SLIDE",
  SLIDE_ERROR: "SLIDE_ERROR",
};

export const SET_USERS = "SET_USERS";

export const HOST_TYPES = {
  SET_HOST: "SET_HOST",
  FETCH_HOST_DETAILS_SUCCESS: "FETCH_HOST_DETAILS_SUCCESS",
  HOST_ERROR: "HOST_ERROR",
};

export const NOTIFICATION_TYPES = {
  FETCH_NOTIFICATION_SUCCESS: "FETCH_NOTIFICATION_SUCCESS",
  FETCH_NOTIFICATION_ERROR: "FETCH_NOTIFICATION_ERROR",
  SENDALERT: "SENDALERT",
};

export const TITLES_TYPES = {
  FETCH_TITLES: "FETCH_TITLES",
  UPDATE_TITLES: "UPDATE_TITLES",
};

export const REFERRAL_TYPES = {
  FETCH_REFERRALS_SUCCESS: "FETCH_REFERRALS_SUCCESS",
  FETCH_REFERRALS_FAIL: "FETCH_REFERRALS_FAIL",
  FETCH_EXPERIENCE_REFERRALS_SUCCESS: "FETCH_EXPERIENCE_REFERRALS_SUCCESS",
  FETCH_EXPERIENCE_REFERRALS_FAIL: "FETCH_EXPERIENCE_REFERRALS_FAIL",
  FETCH_HOME_REFERRALS_SUCCESS: "FETCH_HOME_REFERRALS_SUCCESS",
  FETCH_HOME_REFERRALS_FAIL: "FETCH_HOME_REFERRALS_FAIL",
};

export const INQUIRY_TYPES = {
  ADD_INQUIRY_SUCCESS: "ADD_INQUIRY_SUCCESS",
  FETCH_INQUIRY_SUCCESS: "FETCH_INQUIRY_SUCCESS",
  UPDATE_INQUIRY_SUCCESS: "UPDATE_INQUIRY_SUCCESS",
  FETCH_INQUIRY_BYID_SUCCESS: "FETCH_INQUIRY_BYID_SUCCESS",
};

export const DISCOUNTCODE_TYPES = {
  FETCH_DISCOUNTCODES_SUCCESS: "FETCH_DISCOUNTCODES_SUCCESS",
  FFETCH_DISCOUNTCODES_FAIL: "FETCH_DISCOUNTCODES_FAIL",
  FETCH_DISCOUNTCODE_DETAILS_SUCCESS: "FETCH_DISCOUNTCODE_DETAILS_SUCCESS",
  FETCH_DISCOUNTCODE_DETAILS_FAIL: "FETCH_DISCOUNTCODE_DETAILS_FAIL",
  CREATE_DISCOUNTCODE_SUCCESS: "CREATE_DISCOUNTCODE_SUCCESS",
  CREATE_DISCOUNTCODE_FAIL: "CREATE_DISCOUNTCODE_FAIL",
};

export const ACTION_ALERT_TYPES = {
  NOTICATION_SUCCESS: "NOTICATION_SUCCESS",
  NOTICATION_FAILURE: "NOTICATION_FAILURE",
  NOTICATION_EMPTY: "NOTICATION_EMPTY",
};

export const EXP_CASH_BOOKING_TYPES = {
  FETCH_EXP_CASH_BOOKING_SUCCESS: "FETCH_EXP_CASH_BOOKING_SUCCESS",
  FETCH_EXP_CASH_BOOKING_BYID_SUCCESS: "FETCH_EXP_CASH_BOOKING_BYID_SUCCESS",
};

export const HOME_CASH_BOOKING_TYPES = {
  FETCH_HOME_CASH_BOOKING_SUCCESS: "FETCH_HOME_CASH_BOOKING_SUCCESS",
  FETCH_HOME_CASH_BOOKING_BYID_SUCCESS: "FETCH_HOME_CASH_BOOKING_BYID_SUCCESS",
};

export const POST_TYPES = {
  CREATE_POST_SUCCESS: "CREATE_POST_SUCCESS",
  CREATE_POST_FAIL: "CREATE_POST_FAIL",
  FETCH_POSTS_SUCCESS: "FETCH_POSTS_SUCCESS",
  FETCH_POSTS_FAIL: "FETCH_POSTS_FAIL",
  FETCH_POST_SUCCESS: "FETCH_POST_SUCCESS",
  EDIT_POST_SUCCESS: "EDIT_POST_SUCCESS",
};

export const TRANSACTION_TYPES = {
  FETCH_TRANSACTIONS_SUCCESS: "FETCH_TRANSACTIONS_SUCCESS",
};

export const WISHLIST_TYPES = {
  FETCH_WHISHLIST_SUCCESS: "FETCH_WHISHLIST_SUCCESS",
  FETCH_USER_WISHLIST_SUCCESS: "FETCH_USER_WISHLIST_SUCCESS",
};

export const EVENT_TYPES = {
  FETCH_EVENTS_SUCCESS: "FETCH_EVENTS_SUCCESS",
  FETCH_EVENT_SUCCESS: "FETCH_EVENT_SUCCESS",
};

export const BOOK_EVENT_TYPES = {
  FETCH_BOOK_EVENTS_SUCCESS: "FETCH_BOOK_EVENTS_SUCCESS",
  FETCH_BOOK_EVENT_SUCCESS: "FETCH_BOOK_EVENT_SUCCESS",
};

export const SHOP_TYPES = {
  FETCH_SHOPS_SUCCESS: "FETCH_SHOPS_SUCCESS",
  FETCH_SHOP_SUCCESS: "FETCH_SHOP_SUCCESS",
  FETCH_PRODCUTS_SUCCESS: "FETCH_PRODCUTS_SUCCESS",
  FETCH_PRODCUT_SUCCESS: "FETCH_PRODCUT_SUCCESS",
  FETCH_CATEGORYS_SUCCESS: "FETCH_CATEGORYS_SUCCESS",
  FETCH_CATEGORY_SUCCESS: "FETCH_CATEGORY_SUCCESS",
};

export const GET_REVIEWS = "GET_REVIEWS";
export const REVIEWS = "REVIEWS";
