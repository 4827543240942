import { DESTINATION_TYPES } from "store/types";
import { refactorDestinationObj } from "utils/misc";

const initState = {
  destinations: [],
  errorMessage: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case DESTINATION_TYPES.SET_DESTINATIONS:
      return {
        ...state,
        destinations: refactorDestinationObj(action.destinations),
      };
    case DESTINATION_TYPES.DESTINATIONS_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};
