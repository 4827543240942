import { SHOP_TYPES } from "store/types";
import {
  refactorShopObj,
  refactorProductObj,
  refactorShopCatsObj,
} from "utils/misc";

const initialState = {
  shops: [],
  shop: null,
  products: [],
  product: null,
  categories: [],
  category: {},
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SHOP_TYPES.FETCH_SHOPS_SUCCESS:
      return {
        ...state,
        shops: refactorShopObj(payload),
      };
    case SHOP_TYPES.FETCH_SHOP_SUCCESS:
      return {
        ...state,
        shop: payload,
      };
    case SHOP_TYPES.FETCH_PRODCUTS_SUCCESS:
      return {
        ...state,
        products: refactorProductObj(payload),
      };
    case SHOP_TYPES.FETCH_PRODCUT_SUCCESS:
      return {
        ...state,
        product: payload,
      };
    case SHOP_TYPES.FETCH_CATEGORYS_SUCCESS:
      return {
        ...state,
        categories: refactorShopCatsObj(payload),
      };
    case SHOP_TYPES.FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        category: payload,
      };
    default:
      return state;
  }
};
