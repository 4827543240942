import { SET_USERS, WISHLIST_TYPES } from "store/types";
import {
  formate_csv_data,
  refactorUserObj,
  refactorWishListObj,
} from "utils/misc";

const INIT_STATE = {
  users: [],
  totalUsers: 0,
  user: {},
  csv_data: [],
  withWishLists: [],
  userHomeWishlist: [],
  userExperienceWishlist: [],
};

const getUsersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        totalUsers: action.payload.userTotal,
        users: refactorUserObj(action.payload.users),
        csv_data: formate_csv_data(action.payload.users),
      };
    case WISHLIST_TYPES.FETCH_WHISHLIST_SUCCESS:
      return {
        ...state,
        withWishLists: refactorWishListObj(action.payload),
      };
    case WISHLIST_TYPES.FETCH_USER_WISHLIST_SUCCESS:
      return {
        ...state,
        userHomeWishlist: action.payload.data.homes,
        userExperienceWishlist: action.payload.data.experiences,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

export default getUsersReducer;
