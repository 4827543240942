import { POST_TYPES } from "store/types";
import { refactorPostsObj } from "utils/misc";

const INITIAL_STATE = {
  posts: [],
  post: {},
};

const postReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_TYPES.CREATE_POST_SUCCESS:
      return {
        ...state,
        message: "Post created successfull",
      };
    case POST_TYPES.FETCH_POSTS_SUCCESS:
      return {
        ...state,
        posts: refactorPostsObj(action.payload),
      };
    case POST_TYPES.FETCH_POST_SUCCESS:
      return {
        ...state,
        post: action.payload,
      };
    default:
      return state;
  }
};

export default postReducers;
