import { useState } from "react";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { toast } from "react-toastify";

function formatPhoneNumber(phoneNumberString) {
  if (phoneNumberString.toString().length > 0) {
    const cleaned = phoneNumberString.toString().replace(/\D/g, "");
    const match = cleaned.match(/^(\d{2})(\d{3})(\d{4})$/);
    if (match) {
      return `0${match[1]}${match[2]}${match[3]}`;
    } else {
      if (phoneNumberString) {
        const contact = phoneNumberString?.toString();
        const startCount = contact.length - 9;
        return formatPhoneNumber(contact.slice(startCount));
      }
      {
        toast.warning("User has no contact");
        return false;
      }
    }
  }
}

const useFileExtractor = () => {
  let [data, setData] = useState([
    {
      Phone: "0759974421",
    },
    {
      Phone: "0777381718",
    },
  ]);

  const extractData = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (fileExtension === "csv") {
      Papa.parse(file, {
        complete: (result) => {
          const [headers, ...rows] = result.data;
          const formattedData = rows.map((row) =>
            headers.reduce((obj, header, index) => {
              obj[header] = row[index];
              return obj;
            }, {})
          );
          setData(formattedData);
        },
        header: false,
      });
    } else if (["xlsx", "xls"].includes(fileExtension)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const [headers, ...rows] = jsonData;
        const formattedData = rows.map((row) =>
          headers.reduce((obj, header, index) => {
            obj[header] = row[index];
            return obj;
          }, {})
        );
        setData(formattedData);
      };
      reader.readAsBinaryString(file);
    } else {
      console.error("Unsupported file format");
    }
  };

  data = data.map((item) => ({ Phone: formatPhoneNumber(item.Phone) }));

  return { data, extractData };
};

export default useFileExtractor;
