// assets
import {
  IconHome,
  IconShoppingBag,
  IconCalendar,
  IconWindmill,
} from "@tabler/icons";

// constant
const icons = {
  IconHome,
  IconShoppingBag,
  IconCalendar,
  IconWindmill,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: "pages",
  title: "Products",
  type: "group",
  children: [
    {
      id: "authentication",
      title: "Experiences",
      type: "collapse",
      icon: icons.IconWindmill,
      children: [
        {
          id: "expList",
          title: "Experiences List",
          type: "item",
          url: "/experiences",
        },
        {
          id: "experience-bookings",
          title: "Bookings",
          type: "item",
          url: "/experience-bookings",
        },
      ],
    },
    {
      id: "homes",
      title: "Homes",
      type: "collapse",
      icon: icons.IconHome,

      children: [
        {
          id: "homes-list",
          title: "Homes List",
          type: "item",
          url: "/homes",
        },
        {
          id: "reservation",
          title: "Reservations",
          type: "item",
          url: "/reservations",
        },
        {
          id: "booking",
          title: "Bookings",
          type: "item",
          url: "/home-bookings",
        },
      ],
    },
    {
      id: "shop",
      title: "Stores",
      type: "collapse",
      icon: icons.IconShoppingBag,

      children: [
        {
          id: "stores-list",
          title: "Stores",
          type: "item",
          url: "/stores",
        },
        {
          id: "products-list",
          title: "Products",
          type: "item",
          url: "/products",
        },
        {
          id: "categories-list",
          title: "Categories",
          type: "item",
          url: "/categories",
        },
      ],
    },
    {
      id: "event",
      title: "Events",
      type: "collapse",
      icon: icons.IconCalendar,

      children: [
        {
          id: "events-list",
          title: "Events",
          type: "item",
          url: "/events",
        },
        {
          id: "event-bookings",
          title: "Bookings",
          type: "item",
          url: "/event-bookings",
        },
      ],
    },
  ],
};

export default pages;
