import { TRANSACTION_TYPES } from "store/types";
import { format_transactions_data } from "utils/misc";

const INITIAL_STATE = {
  transactions: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRANSACTION_TYPES.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: format_transactions_data(action.payload),
      };

    default:
      return state;
  }
};
