import React from "react";
import { useDispatch } from "react-redux";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  TextField,
  Button,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
// project imports
import MainCard from "ui-component/cards/MainCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import TabTableComponent from "ui-component/table/CustomTabs";
import Tasks from "ui-component/tasks";
import { bulkSms as sendBulkSms } from "store/actions/notification.actions";
// Actions
import useFileExtractor from "views/utilities/useFileExtractor";
import { FileUpload } from "@mui/icons-material";
import { toast } from "react-toastify";

export const BulkMessage = ({}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [formState, setFormState] = React.useState({ all: false });

  const handleFieldChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  const { data, extractData } = useFileExtractor();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    extractData(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const recipients = data.map((item) => `${item.Phone}`);
    if (formState.notificationBody) {
      dispatch(
        sendBulkSms({
          recipients,
          body: formState.notificationBody,
        })
      );
    } else {
      toast.warning("Please add message");
    }
  };

  return (
    <MainCard title="Compose Message">
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            component="label"
            variant="subtitle1"
            style={{ textAlign: "center" }}
          >
            Upload CSV or excel file
          </Typography>
          <input
            style={{ display: "none" }}
            type="file"
            id="icon-button-file"
            onChange={handleFileUpload}
            accept=".csv,.xlsx,.xls"
          />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <FileUpload
                style={{
                  fontSize: "2rem",
                }}
              />
            </IconButton>
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            label="Message Body"
            margin="normal"
            name="notificationBody"
            multiline
            onChange={(event) =>
              handleFieldChange(event, "type", event.target.value)
            }
            type="text"
            sx={{ ...theme.typography.customInput }}
          />
        </Grid>
        <Grid item md={6}>
          <AnimateButton>
            <Button
              disableElevation
              variant="outlined"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleSubmit}
            >
              SEND
            </Button>
          </AnimateButton>
        </Grid>
      </Grid>
      <Box sx={{ mt: 10 }}>
        <Typography variant="h3"> Data Preview</Typography>

        <TabTableComponent
          title="Status"
          headerColor="primary"
          tabs={[
            {
              tabName: `Data`,
              tabIcon: "",
              tabContent: (
                <Tasks
                  tableHeaderColor="primary"
                  tableHead={["Phone"]}
                  tasksIndexes={[0, 1, 2, 3]}
                  tableData={data.slice(0, 5) || []}
                  handleViewItem={() => {}}
                  handleRemoveItem={() => {}}
                />
              ),
            },
          ]}
        />
      </Box>
    </MainCard>
  );
};

export default BulkMessage;
