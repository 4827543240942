import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  useEffect(() => {
    const isAuth = localStorage.getItem("ISAUTH");
    if (!isAuth) {
      navigate("/login");
    } else {
      const reloadCount = parseInt(localStorage.getItem("ISAUTHCOUNT"));
      if (reloadCount >= 200) {
        localStorage.clear();
      } else {
        localStorage.setItem("ISAUTHCOUNT", `${reloadCount + 1}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <ToastContainer position="top-center" />
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
