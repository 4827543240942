import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  TableHead,
  Tooltip,
  IconButton,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  Paper,
} from "@mui/material";
// @material-ui/icons
import { Close, Check, Edit } from "@mui/icons-material";
// core components
import styles from "assets/jss/material-dashboard/components/tasksStyle";

const useStyles = makeStyles(styles);

function Tasks({
  tableHead,
  tableData,
  tableHeaderColor,
  handleViewItem,
  handleRemoveItem,
  // tableName,
  // handlePayHost,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [limit, setLimit] = React.useState(50);
  const [page] = React.useState(1);
  const [startVal, setStartValState] = React.useState(0);
  const [endVal, setEndValState] = React.useState(limit);

  const handleLimitChange = (event) => {
    console.log(event.target.value);
    setLimit(event.target.value);
    setEndValState(event.target.value);
  };

  const handlePageChange = (event, page) => {
    if (page === 2) {
      if (endVal >= tableData.length) {
        setStartValState(0);
        setEndValState(limit);
      }
      setStartValState(endVal + 1);
      setEndValState(endVal + limit);
    } else {
      if (startVal < 1) {
        setStartValState(0);
        setEndValState(limit);
      }
      setStartValState(startVal - limit);
      setEndValState(endVal - limit);
    }
  };

  const checkPropValue = (val) =>
    val && String(val).match(/^[0-9a-fA-F]{24}$/) ? null : val;

  return (
    <Paper>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table sx={{ width: "max-content" }} className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead
              sx={{ backgroundColor: theme.palette.primary }}
              className={classes[tableHeaderColor + "TableHeader"]}
            >
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      key={key}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {tableData.slice(startVal, endVal).map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  {Object.values(prop).map((prop, key) => {
                    return (
                      <>
                        {prop === true ? (
                          <TableCell className={classes.tableCell} key={key}>
                            <Check
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.check
                              }
                            />
                          </TableCell>
                        ) : null}
                        {prop === false ? (
                          <TableCell className={classes.tableCell} key={key}>
                            <Close
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.close
                              }
                            />
                          </TableCell>
                        ) : null}
                        {prop !== false && prop !== true ? (
                          <TableCell className={classes.tableCell} key={key}>
                            {checkPropValue(prop)}
                          </TableCell>
                        ) : null}
                      </>
                    );
                  })}
                  <TableCell key={key} className={classes.tableActions}>
                    <Tooltip
                      id="tooltip-top"
                      title="Edit Task"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                      onClick={() => handleViewItem(prop)}
                    >
                      <IconButton
                        aria-label="Edit"
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top-start"
                      title="Remove"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                      onClick={() => handleRemoveItem(prop)}
                    >
                      <IconButton
                        aria-label="Close"
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + " " + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    {/* {tableName === "booking" ? (
                    <Tooltip
                      id="tooltip-top-start"
                      title="Make Host Payment"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                      onClick={() => handlePayHost(prop)}
                    >
                      <IconButton
                        aria-label="money"
                        className={classes.tableActionButton}
                      >
                        <Money
                          className={
                            classes.tableActionButtonIcon +
                            " " +
                            classes.success
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  ) : null} */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={tableData.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[limit, 200, tableData.length]}
        />
      </TableContainer>
    </Paper>
  );
}

Tasks.propTypes = {
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.arrayOf(PropTypes.node),
  rtlActive: PropTypes.bool,
  checkedIndexes: PropTypes.array,
  handleViewItem: PropTypes.func,
};

export default Tasks;
