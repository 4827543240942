import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import experienceReducer from "./experienceReducer";
import homeReducers from "./home.reducers";
import shopReducers from "./shop.reducers";
import eventReducers from "./event.reducers";
import userReducers from "./user.reducers";
import hostReducers from "./host.reducers";
import customerReducers from "./customer.reducers";
import transactionReducers from "./transaction.reducers";
import postReducers from "./post.reducers";
import destinationReducers from "./destination.reducers";
import inquiryReducers from "./inquiry.reducers";
import reviewReducers from "./review.reducers";
import notificationReducers from "./notification.reducers";
import dashboardReducers from "./dashboard.reducers";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  experienceReducer,
  homeReducers,
  shopReducers,
  eventReducers,
  userReducers,
  hostReducers,
  customerReducers,
  transactionReducers,
  postReducers,
  destinationReducers,
  inquiryReducers,
  reviewReducers,
  notificationReducers,
  dashboardReducers,
});

export default reducer;
