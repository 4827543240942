import React from "react";
import moment from "moment";
import Resizer from "react-image-file-resizer";
// export const APIURL = "http://localhost:8080/api/v1/";
export const APIURL = "https://api.tubayo.com/api/v1/";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      400,
      400,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const compressedFile = async (file) => {
  const dataURI = await resizeFile(file);
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const currency_format = (amount, decimal = ".", thousands = ",") => {
  let decimalCount = 0;
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {}
};

export const longDateFormat = (date) => {
  const newDate = date && date.replace(/["']/g, "");
  var d = new Date(newDate);
  return (
    ("0" + d.getDate()).slice(-2) +
    "/" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    d.getFullYear() +
    " " +
    d.getHours() +
    ":" +
    d.getMinutes() +
    " " +
    (d.getHours() >= 12 ? "pm" : "am")
  );
};

const formatDate = (date) =>
  moment(date && date.split("T")[0], "YYYY-MM-DD").format("DD-MMM");

export const dataURItoBlob = (dataurl, filename) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let croppedImage = new File([u8arr], filename, { type: mime });
  return croppedImage;
};

export const formate_csv_data = (payload) => {
  let userName, email, phone_no, method;
  let data = [];

  for (let i = 0; i < payload.length; i++) {
    if (payload[i].method === "google") {
      userName = payload[i].google.userName;
      email = payload[i].google.email;
      phone_no = payload[i].google.phoneNumber;
      method = "google";
    } else if (payload[i].method === "facebook") {
      userName = payload[i].facebook.userName;
      email = payload[i].facebook.email;
      phone_no = payload[i].facebook.phoneNumber;
      method = "facebook";
    } else if (payload[i].method === "apple") {
      userName = payload[i].apple.userName;
      email = payload[i].apple.email;
      phone_no = payload[i].apple.phoneNumber;
      method = "apple";
    } else {
      userName = payload[i].local.userName;
      email = payload[i].local.email;
      phone_no = payload[i].local.phoneNumber;
      method = "local";
    }

    data = [
      ...data,
      {
        Username: userName,
        PhoneNumber: phone_no,
        Email: email,
        Method: method,
      },
    ];
  }

  return data;
};

export const generateCSV_hostData = (hosts) => {
  let data = [];
  for (let i = 0; i < hosts.length; i++) {
    data = [
      ...data,
      {
        First_Name: hosts[i].firstName,
        Last_Name: hosts[i].lastName,
        Email: hosts[i].email,
        Telephone: hosts[i].phoneNumber,
        hasListings: hosts[i].hasListings ? true : false,
      },
    ];
  }
  return data;
};

export const generateCSV_postData = (posts) => {
  const data = posts.map((post) => ({
    title: post.postTitle,
    body: post.postBody,
    author: post.author,
  }));
  return data;
};

export const generateCSV_referralData = (referrals) => {
  let data = [];
  for (let i = 0; i < referrals.length; i++) {
    data = [
      ...data,
      {
        Code: referrals[i].code || referrals[i].referral,
        Referred: referrals[i].referred,
        Email: referrals[i].email,
        Telephone: referrals[i].phoneNumber,
      },
    ];
  }
  return data;
};

export const generateCSV_event_bookings = (booking, booked) => {
  let data = [];
  for (let i = 0; i < booking.length; i++) {
    data = [
      ...data,
      {
        Name: `${booking[i].firstName} ${booking[i].lastName}`,
        Email: booking[i].email,
        Telephone: booking[i].phoneNumber,
        Booked: booked,
        Ticket: booking[i].ticket,
        Guests: booking[i].numberOfTickets,
        Amount: booking[i].amountPaid,
        ListingTitle: booking[i].eventPaidFor,
      },
    ];
  }
  return data;
};

export const generateCSV_bookings = (booking, booked) => {
  let data = [];
  for (let i = 0; i < booking.length; i++) {
    data = [
      ...data,
      {
        Name: `${booking[i].firstName} ${booking[i].lastName}`,
        Email: booking[i].email,
        Telephone: booking[i].phoneNumber,
        Booked: booked,
        ListingTitle: `${
          booking[i].experiencePaidFor ||
          booking[i].homePaidFor ||
          booking[i].eventPaidFor
        }`,
      },
    ];
  }
  return data;
};

export const refactorUserObj = (users) => {
  let newUserData = [];
  newUserData = users.map((user, idx) => {
    let userName,
      email,
      method,
      phone_no,
      no = users.length - idx,
      date = longDateFormat(user.createdDate);
    if (user.method === "google") {
      userName = user.google.userName;
      email = user.google.email;
      phone_no = user.google.phoneNumber || "";
      method = "google";
    } else if (user.method === "facebook") {
      userName = user.facebook.userName;
      email = user.facebook.email;
      phone_no = user.facebook.phoneNumber || "";
      method = "facebook";
    } else if (user.method === "apple") {
      userName = user.apple.userName;
      email = user.apple.email;
      phone_no = user.apple.phoneNumber || "";
      method = "apple";
    } else {
      userName = user.local.userName;
      email = user.local.email;
      phone_no = user.local.phoneNumber || "";
      method = "local";
    }
    return { no, userName, email, phone_no, method, date, _id: user._id };
  });
  return newUserData;
};

export const refactorWishListObj = (wishLists) => {
  let newUserData = [];
  newUserData = wishLists.map((wishList, idx) => {
    let userName,
      email,
      method,
      phone_no,
      // no = wishLists.length - idx,
      date = longDateFormat(wishList.createdAt),
      userId = wishList.userId._id;

    if (wishList.userId.method === "google") {
      userName = wishList.userId.google.userName;
      email = wishList.userId.google.email;
      phone_no = wishList.userId.google.phoneNumber || "";
      method = "google";
    } else if (wishList.userId.method === "facebook") {
      userName = wishList.userId.facebook.userName;
      email = wishList.userId.facebook.email;
      phone_no = wishList.userId.facebook.phoneNumber || "";
      method = "facebook";
    } else if (wishList.userId.method === "apple") {
      userName = wishList.userId.apple.userName;
      email = wishList.userId.apple.email;
      phone_no = wishList.userId.apple.phoneNumber || "";
      method = "apple";
    } else {
      userName = wishList.userId.local.userName;
      email = wishList.userId.local.email;
      phone_no = wishList.userId.local.phoneNumber || "";
      method = "local";
    }

    const userWishList = wishLists.reduce((acc, val) => {
      acc = val.userId._id === userId ? acc + 1 : acc + 0;
      return acc;
    }, 0);

    return {
      userWishList,
      userName,
      email,
      phone_no,
      method,
      date,
      _id: userId,
    };
  });

  const dataIdArray = newUserData.map((data) => data._id);
  const uniqeDataSet = new Set(dataIdArray);
  const uniqeData = [...uniqeDataSet];
  const transformedData = newUserData.reduce((accumlator, data) => {
    accumlator[data._id] = data;
    return accumlator;
  }, {});
  const newDataCollection = uniqeData.map((dataId) => transformedData[dataId]);
  return newDataCollection;
};

const filter_listings = (listingsArray) => {
  let approved_listings = [],
    pending_listing = [],
    app_count = 0,
    pend_count = 0;
  listingsArray.forEach((listing) => {
    if (listing.approved) {
      app_count = ++app_count;
      approved_listings = [...approved_listings, { no: app_count, ...listing }];
    } else {
      pend_count = ++pend_count;
      pending_listing = [...pending_listing, { no: pend_count, ...listing }];
    }
  });

  return {
    approved_listings,
    pending_listing,
    total_listings: listingsArray.length,
  };
};

export const refactorExperienceObj = (experiences) => {
  let newExpData = [];
  newExpData = experiences.map((experience, idx) => {
    // let no = experiences.length - idx;
    const {
      experienceTitle,
      location,
      country,
      createdDate,
      servicePercentage,
      approved,
      trending,
      _id,
    } = experience;
    return {
      // no,
      experienceTitle,
      country,
      location,
      servicePercentage,
      createdDate: formatDate(createdDate),
      approved,
      trending,
      _id,
    };
  });

  return filter_listings(newExpData);
};

export const refactorEventObj = (events) => {
  let newExpData = [];
  newExpData = events.map((event, idx) => {
    const {
      eventName,
      address,
      country,
      servicePercentage,
      isApproved,
      startDate,
      _id,
    } = event;
    return {
      // no,
      eventName,
      country,
      address,
      servicePercentage,
      approved: isApproved,
      startDate: formatDate(startDate),
      _id,
    };
  });

  return filter_listings(newExpData);
};

export const refactorHomesObj = (homes) => {
  let newHomesData = [];
  newHomesData = homes.map((home, idx) => {
    // let no = homes.length - idx;
    const {
      homeTitle,
      homeLocation,
      homeCountry,
      servicePercentage,
      createdAt,
      approved,
      hasOffer,
      _id,
    } = home;
    return {
      // no,
      homeTitle,
      homeCountry,
      homeLocation,
      servicePercentage,
      createdAt: formatDate(createdAt),
      approved,
      hasOffer,
      _id,
    };
  });
  return filter_listings(newHomesData);
};

const filter_hosts = (hostsArray) => {
  let hasListings_hosts = [],
    noListings_hosts = [],
    has_count = 0,
    no_count = 0;
  hostsArray.forEach((host) => {
    if (host.hasListings) {
      has_count = ++has_count;
      hasListings_hosts = [...hasListings_hosts, { no: has_count, ...host }];
    } else {
      no_count = ++no_count;
      noListings_hosts = [...noListings_hosts, { no: no_count, ...host }];
    }
  });

  return {
    hasListings_hosts: hasListings_hosts || [],
    noListings_hosts: noListings_hosts || [],
    hostsArray,
    total_hosts: hostsArray.length,
  };
};

export const refactorHostsObj = (hosts) => {
  let newHostsData = [];
  newHostsData = hosts.map((host, idx) => {
    let no = hosts.length - idx;
    const {
      firstName,
      lastName,
      userName,
      registeredDate,
      _id,
      hasListings,
      isInterested,
    } = host;
    return {
      no,
      firstName,
      lastName,
      userName,
      hasListings: hasListings ? true : false,
      isInterested: isInterested ? true : false,
      registeredDate: longDateFormat(registeredDate),
      _id,
    };
  });
  return filter_hosts(newHostsData);
};

export const refactorPostsObj = (Posts) => {
  let newPostsData = [];
  newPostsData = Posts.map((Post, idx) => {
    let no = Posts.length - idx;
    const { postTitle, postBody, author, createdDate, _id, postImage } = Post;
    return {
      no,
      imgComp: (
        <img
          src={postImage}
          alt="slide"
          style={{ width: "15rem", height: "7rem" }}
        />
      ),
      postTitle,
      postBody: `${postBody.slice(0, 250)}...`,
      author,
      createDate: longDateFormat(createdDate),
      _id,
    };
  });
  return newPostsData;
};

export const refactorBookingObj = (bookings) => {
  let newBookingData = [];
  newBookingData = bookings.map((booking, idx) => {
    let no = bookings.length - idx;
    const title =
      booking.experiencePaidFor || booking.homePaidFor || booking.eventPaidFor;
    const bookingDate = booking.bookingDate || booking.createdAt;
    const { firstName, lastName, phoneNumber, hostTel, _id, hostIsPaid } =
      booking;
    return {
      no,
      bookingDate: formatDate(bookingDate),
      title,
      firstName,
      lastName,
      phoneNumber,
      hostTel,
      hostIsPaid,
      _id,
    };
  });
  return newBookingData;
};

export const refactorDestinationObj = (destinations) => {
  let newDestData = [];
  newDestData = destinations.map((destination, idx) => {
    let no = ++idx;
    const { imgUrl, destinationTitle, destinationCountry, _id } = destination;
    return {
      no,
      imgComp: (
        <img
          src={imgUrl}
          alt="slide"
          style={{ width: "15rem", height: "7rem" }}
        />
      ),
      destinationTitle,
      destinationCountry,
      _id,
    };
  });
  return newDestData;
};

export const refactorReviewObj = (reviews) => {
  let newReviewData = [];
  newReviewData = reviews.map((review, idx) => {
    let no = reviews.length - idx;
    const { rating, comment, _id } = review;
    return {
      no,
      rating,
      comment: comment.length > 100 ? comment.slice(0, 100) : comment,
      _id,
    };
  });
  return newReviewData;
};

export const refactorNotificationsObj = (notifications) => {
  let newNotificationData = [];
  newNotificationData = notifications.map((notification, idx) => {
    let no = ++idx;
    const { notificationTitle, notificationBody, sentAt, _id } = notification;
    return {
      no,
      notificationTitle,
      notificationBody,
      sentAt: formatDate(sentAt),
      _id,
    };
  });
  return newNotificationData;
};

export const refactorShopCatsObj = (categories) => {
  let newCategoryData = [];
  newCategoryData = categories.map((category, idx) => {
    let no = ++idx;
    const { name, subCategoies, createdAt, _id } = category;
    return {
      no,
      name,
      subCategoies: subCategoies && subCategoies.length,
      createdAt: formatDate(createdAt),
      _id,
    };
  });
  return newCategoryData;
};

export const refactorDiscountcodeObj = (discountCodes) => {
  let newDiscountCodeData = [];
  newDiscountCodeData = discountCodes.map((discountCode, idx) => {
    let no = ++idx;
    const { code, percentageDiscount, maximumLimit, isActive, _id } =
      discountCode;
    return { no, code, percentageDiscount, maximumLimit, isActive, _id };
  });
  return newDiscountCodeData;
};

export const refactorReferralsObj = (referrals) => {
  let newReferralData = [];
  newReferralData = referrals.map((referral, idx) => {
    let no = referrals.length - idx;
    const { code, referred, email, phoneNumber, createdAt } = referral;
    return {
      no,
      code,
      referred,
      email,
      phoneNumber,
      createdAt: formatDate(createdAt),
    };
  });
  return newReferralData;
};

export const refactorInquiryObj = (inquiries) => {
  let newInquiries = [];
  newInquiries = inquiries.map((inq, idx) => {
    let no = inquiries.length - idx;
    const {
      name,
      phoneNumber,
      email,
      inquiry,
      handler,
      createdAt,
      _id,
      status,
    } = inq;
    let date = formatDate(createdAt);
    return {
      No: no,
      Date: date,
      Names: name,
      Tel: phoneNumber,
      Email: email,
      Inquiry: inquiry,
      Handler: handler,
      Status: status,
      _id,
    };
  });
  return newInquiries;
};

export const refactorCustomerObj = (Customers) => {
  let newCustomers = [];
  newCustomers = Customers.map((customer, idx) => {
    let no = Customers.length - idx;
    const { firstName, lastName, phoneNumber, email, createAt, _id } = customer;
    let date = formatDate(createAt);
    return {
      No: no,
      Date: date,
      Names: `${firstName} ${lastName}`,
      Tel: phoneNumber,
      Email: email,
      _id,
    };
  });
  return newCustomers;
};

export const refactorExpCashObj = (data) => {
  let newData = [];
  newData =
    data &&
    data.map((dataItem, idx) => {
      let no = data.length - idx;
      const {
        names,
        phoneNumber,
        email,
        host_names,
        host_phoneNumber,
        experienceBooked,
        number_of_people,
        amount_paid,
        checkin_date,
        createdAt,
        _id,
      } = dataItem;
      let date = formatDate(createdAt);
      return {
        no,
        date,
        names,
        phoneNumber,
        email,
        host_names,
        host_phoneNumber,
        experienceBooked,
        number_of_people,
        checkin_date: formatDate(checkin_date),
        amount_paid,
        _id,
      };
    });

  return newData;
};

export const refactorHomeCashObj = (data) => {
  let newData = [];
  newData =
    data &&
    data.map((dataItem, idx) => {
      let no = data.length - idx;
      const {
        host,
        home,
        firstName,
        lastName,
        phoneNumber,
        nightsToStay,
        checkInDate,
        numberOfGuests,
        createdAt,
        status,
        _id,
      } = dataItem;
      let date = formatDate(createdAt);

      return {
        no,
        date,
        names: `${firstName} ${lastName}`,
        phoneNumber,
        host_names: `${host?.firstName} ${host?.lastName}`,
        host_phoneNumber: host?.phoneNumber,
        homeBooked: home && home.homeTitle,
        nightsToStay,
        checkin_date: new Date(checkInDate).toDateString(),
        numberOfGuests,
        status,
        _id,
      };
    });

  return newData;
};

export const format_transactions_data = (transactions) => {
  let newData = [];
  newData =
    transactions &&
    transactions.map((transaction, idx) => {
      let no = transactions.length - idx;
      const {
        user_names,
        user_email,
        listing_name,
        host_amount,
        create_date,
        listing_type,
        user_contact,
        paid_amount,
        _id,
      } = transaction;
      let date = formatDate(create_date);
      return {
        no,
        user_names,
        user_email,
        user_contact,
        listing_name,
        listing_type,
        paid_amount,
        host_amount,
        date,
        _id,
      };
    });
  return newData;
};

export const refactorShopObj = (shops) => {
  let newShopData = [];
  newShopData = shops.map((shop, idx) => {
    // let no = shops.length - idx;

    return {
      // no,
      name: shop.name || "",
      country: shop.country || "",
      contact: shop.phone || "",
      email: shop.email || "",
      createdAt: formatDate(shop.createdAt),
      approved: shop.isActive,
      _id: shop._id,
    };
  });
  return filter_listings(newShopData);
};

export const refactorProductObj = (Products) => {
  let newProductData = [];
  newProductData = Products.map((Product, idx) => {
    // let no = Products.length - idx;

    return {
      // no,
      name: Product?.name || "",
      category: Product?.category?.name || "",
      brand: Product.brand || "",
      price: `${Product.currency} ${Product.price}`,
      store: Product.shop?.name || "",
      approved: Product.shop?.isActive,
      _id: Product._id,
    };
  });
  return filter_listings(newProductData);
};
