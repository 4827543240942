import { NOTIFICATION_TYPES } from "store/types";
import { refactorNotificationsObj } from "utils/misc";

const INITIAL_STATE = {
  message: "",
  status: "",
  notifications: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_TYPES.SENDALERT:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
    case NOTIFICATION_TYPES.FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: refactorNotificationsObj(action.payload),
      };
    case NOTIFICATION_TYPES.FETCH_NOTIFICATION_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
