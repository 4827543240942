// material-ui
import { Box, Typography } from "@mui/material";
import logo from "assets/images/logo.png";
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <img src={logo} alt="Tubayo" width="40" />
      <Typography variant="h4" sx={{ pl: 1 }}>
        Tubayo Admin
      </Typography>
    </Box>
  );
};

export default Logo;
