import axios from "axios";
import { toast } from "react-toastify";
import { NOTIFICATION_TYPES } from "store/types";
import { APIURL } from "utils/misc";

const IOTECH_API_URL = "https://messaging-api.iotec.io/api/";

export const sendNotification = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${APIURL}alerts`, data);
      dispatch({
        type: NOTIFICATION_TYPES.SENDALERT,
        payload: res.data,
      });
      toast.success("Notification sent successfully!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };
};

export const personalNotification = (data) => async (dispatch) => {
  try {
    await axios.post(`${APIURL}alerts/personal`, data);
    toast.success("Notification sent successfully!");
  } catch (error) {
    toast.error(error.message);
  }
};

export const personalMessage = (data) => async () => {
  try {
    await axios.post(`${IOTECH_API_URL}sms-message`, data, {
      headers: {
        "client-id": "1d864de9-f914-46cb-9106-b07f6e6307f0",
        "X-Api-Key": "IO-dRujRXxWQTBQiv6R6YyeCNtnR4ekLOgFt",
      },
    });
    toast.success("Message sent successfully!");
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const bulkSms = (data) => async () => {
  try {
    await axios.post(`${IOTECH_API_URL}msg/bulk/send`, data, {
      headers: {
        "client-id": "1d864de9-f914-46cb-9106-b07f6e6307f0",
        "X-Api-Key": "IO-dRujRXxWQTBQiv6R6YyeCNtnR4ekLOgFt",
      },
    });
    toast.success("Messages sent successfully!");
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const fetchNotificationsAsync = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${APIURL}alerts`);
      dispatch(fetchNotificationSuccess(res.data.notifications));
    } catch (error) {
      dispatch(fetchNotificationFailure(error));
    }
  };
};

export const fetchNotificationSuccess = (notifications) => ({
  type: NOTIFICATION_TYPES.FETCH_NOTIFICATION_SUCCESS,
  payload: notifications,
});

export const fetchNotificationFailure = (error) => ({
  type: NOTIFICATION_TYPES.FETCH_NOTIFICATION_ERROR,
  payload: error.message,
});

export const delete_notification_async =
  (notificationId) => async (dispatch) => {
    try {
      await axios.delete(`${APIURL}alerts/${notificationId}`);
      toast.success("successfully deleted");
    } catch (error) {
      toast.error(error.message);
    }
  };
