import {
  refactorExperienceObj,
  refactorBookingObj,
  generateCSV_bookings,
} from "../../utils/misc";
import { EXPERIENCE_TYPES, BOOKING_TYPES } from "../types";

const INITIAL_STATE = {
  experiences: null,
  experience: null,
  bookedExperiences: [],
  experienceBookingDetails: null,
  experience_csv: [],
};

const experienceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXPERIENCE_TYPES.SET_EXPERIENCE:
      return {
        ...state,
        experiences: refactorExperienceObj(action.experience),
      };
    case EXPERIENCE_TYPES.GET_EXPERIENCE:
      return { ...state, experience: action.payload };
    case BOOKING_TYPES.SET_BOOKING:
      return {
        ...state,
        bookedExperiences: refactorBookingObj(action.booking),
        experience_csv: generateCSV_bookings(action.booking, "Experience"),
      };
    case BOOKING_TYPES.BOOKING:
      return {
        ...state,
        experienceBookingDetails: action.payload,
      };
    default:
      return state;
  }
};

export default experienceReducer;
