import {
  refactorHomesObj,
  refactorHomeCashObj,
  refactorBookingObj,
  generateCSV_bookings,
} from "../../utils/misc";
import { HOME_TYPE, HOME_CASH_BOOKING_TYPES, BOOKING_TYPES } from "../types";

const initialState = {
  homes: null,
  home: null,
  reservations: [],
  reservation: null,
  bookings: [],
  booking: null,
  home_csv: null,
};

const homeReducers = (state = initialState, action) => {
  switch (action.type) {
    case HOME_TYPE.SET_HOME:
      return {
        ...state,
        homes: refactorHomesObj(action.homes),
      };
    case HOME_TYPE.GET_HOME:
      return {
        ...state,
        home: action.payload,
      };
    case HOME_TYPE.EDIT_HOME:
      return {
        ...state,
        successMessage: action.payload.message,
      };
    case HOME_CASH_BOOKING_TYPES.FETCH_HOME_CASH_BOOKING_SUCCESS:
      return {
        ...state,
        reservations: refactorHomeCashObj(action.payload),
      };

    case HOME_CASH_BOOKING_TYPES.FETCH_HOME_CASH_BOOKING_BYID_SUCCESS:
      return {
        ...state,
        reservation: action.payload,
      };
    case BOOKING_TYPES.FETCH_HOME_BOOKINGS:
      return {
        ...state,
        bookings: refactorBookingObj(action.payload),
        home_csv: generateCSV_bookings(action.payload, "Home"),
      };
    case BOOKING_TYPES.FETCH_HOME_BOOKING_DETAILS:
      return {
        ...state,
        booking: action.payload,
      };
    default:
      return state;
  }
};

export default homeReducers;
