import { DASHBOARD_TYPES } from "store/types";

const initialState = {
  dataCounts: null,
  series: null,
  pie: null,
};

const dashboardReducers = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case DASHBOARD_TYPES.DATA_COUNTS:
      return {
        dataCounts: payload.counts,
        series: payload.bar,
        pie: payload.pie,
      };
    case DASHBOARD_TYPES.DATA_BAR:
      return {
        series: payload,
      };
    default:
      return state;
  }
};

export default dashboardReducers;
