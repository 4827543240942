import { CUSTOMER_TYPES } from "store/types";
import { refactorCustomerObj } from "utils/misc";

const INITINAL_STATE = {
  customers: [],
};

export default (state = INITINAL_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_TYPES.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: refactorCustomerObj(action.payload),
      };

    default:
      return state;
  }
};
